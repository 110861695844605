import React from 'react';
import SideBarLink from './SideBarLink'

const Sidebar = props => {
    let organizationConfig = props.organizationConfig
    let updateDefinitionLink = props.updateDefinitionLink
    let apiLinks = []

    if (props.definitionList === null) {
        props.getOrganizationData(organizationConfig.orgName)
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {
            if (props.definitionList[i].name) {
                apiLinks.push(
                    <SideBarLink 
                        key={i}
                        apiLinkData={props.definitionList[i]}
                        updateDefinitionLink={props.updateDefinitionLink}
                    />
                )
            }
        }
    }

  return (
    <div className="side-bar">
        <div className="side-bar-body">
            {/* <SideBarLink apiLinkData={{"leftMenu":"Forgot Api Key","x-tagService":menuPages.menu4}} updateDefinitionLink={updateDefinitionLink}/> */}
            <div className="api-header-sidebar">API</div>
            {apiLinks}
        </div>
    </div>
  )
}

export default Sidebar;